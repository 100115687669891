<template>
  <div class="new-songs"
       v-loading="loading">
    <ul class="song_tar">
      <li class="song_item"
          :class="{ item_active: isActive === item.title }"
          v-for="item in TarBar"
          :key="item.id"
          @click="tarBarClick(item)">{{ item.title }}</li>
    </ul>
    <div class="songs-table">
      <el-table :data="tableData"
                stripe
                style="width: 100%;"
                @row-dblclick="play">

        <el-table-column type="index"
                         :width="indexwidth"></el-table-column>

        <el-table-column :width="imgwidth">
          <template slot-scope="scope">
            <div class="img-wrap">
              <img v-lazy="scope.row.imgUrl">
              <p class="iconfont icon-play"
                 @click="play(scope.row)"></p>
            </div>
          </template>

        </el-table-column>

        <el-table-column prop="songName"
                         label="音乐标题"
                         width=""></el-table-column>

        <el-table-column label="歌手"
                         :width="sinwidth">
          <template slot-scope="scope">
            <div v-for="(x,i) in scope.row.artistInfo"
                 :key="i"
                 style="cursor:pointer;color:#2980b9;display:inline-block"
                 @click="toSinger(x.id)">{{x.name}}<span style="color:#606266;"
                    v-show="scope.row.artistInfo.length != 1 && i!=scope.row.artistInfo.length-1">/</span></div>
          </template>
        </el-table-column>

        <el-table-column prop="album"
                         v-if="columnshow"
                         label="专辑"
                         width="">
          <template slot-scope="scope">
            <span style="cursor:pointer;color:#2980b9;"
                  @click="toAlbum(scope.row.albumId)">{{scope.row.album}}</span>
          </template>
        </el-table-column>

        <el-table-column prop="duration"
                         label="时长"
                         :width="timewidth"></el-table-column>
      </el-table>

    </div>
  </div>
</template>

<script>
import { getNewSongsAPI } from '@/api/api'

export default {
  data () {
    return {
      TarBar: [
        { title: '全部', id: 0 },
        { title: '华语', id: 7 },
        { title: '欧美', id: 96 },
        { title: '日本', id: 8 },
        { title: '韩国', id: 16 }
      ],
      isActive: '全部',
      tableData: [],
      songUrl: '',
      loading: true,
      columnshow: true,
      imgwidth: 120,
      timewidth: '',
      sinwidth: '',
      indexwidth: 50
    }
  },
  created () {
    this.onLoad()
  },
  computed: {
    musicQueue () {
      return this.$store.state.musicQueue
    }
  },
  methods: {
    tarBarClick (item) {
      this.isActive = item.title
      this.onLoad(item.id)
    },
    async onLoad (type) {
      const params = {
        type: type
      }
      const { data: res } = await getNewSongsAPI(params)
      const resultList = res.data.slice(0, 30)
      const songsList = []
      for (const item of resultList) {
        const duration = this.$options.filters.formatDuration(item.duration)
        const song = {
          id: item.id,
          songName: item.name,
          artistInfo: item.artists,
          album: item.album.name,
          albumId: item.album.id,
          imgUrl: item.album.picUrl,
          duration,
          mp3Url: item.mp3Url
        }
        songsList.push(song)
      }
      this.tableData = songsList
      this.loading = false
    },
    toSinger (id) {
      this.$router.push(`/singerDetail?id=${id}`)
    },
    toAlbum (id) {
      this.$router.push(`/album?id=${id}`)
    },
    async play (row) {
      const musicInfo = {
        imgUrl: row.imgUrl,
        artistInfo: row.artistInfo,
        songName: row.songName,
        artistId: row.artistId,
        id: row.id,
        duration: row.duration
      }
      this.playMusic(row, musicInfo)
    }
  },
  mounted () {
    if (document.documentElement.clientWidth < 600) {
      this.columnshow = false
      this.imgwidth = 55
      this.timewidth = 55
      this.indexwidth = 20
    }
  }
}
</script>

<style scoped>
ul {
  list-style: none;
}

.new-songs {
  max-width: 1300px;
  margin: 0 auto;
  padding: 20px;
}

.new-songs >>> .el-loading-spinner {
  top: 50%;
}

.el-table td,
.el-table th.is-leaf {
  border-bottom: none;
}

.el-table::before {
  opacity: 0;
}

.songs-table {
  width: 100%;
}

.img-wrap {
  width: 60px;
  height: 60px;
  position: relative;
}

.img-wrap img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.img-wrap p::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ff0000 !important;
  font-size: 14px;
  cursor: pointer;
}
.song_tar {
  margin: 20px 10px 20px -20px;
  overflow: hidden;
}
.song_tar .song_item {
  float: left;
  margin: 5px 20px;
  cursor: pointer;
}
.item_active {
  color: #ff0000;
}

@media screen and (max-width: 479px) {
  .new-songs {
    padding: 0;
  }
  .song_tar {
    margin: 0;
  }
  .song_tar .song_item {
    float: left;
    font-size: 15px;
    margin: 5px 0 5px 10px;
    cursor: pointer;
  }
  .img-wrap {
    width: 45px;
    height: 45px;
  }
  .el-table {
    font-size: 13px;
  }
  .el-table .cell {
    padding: 0 !important;
  }
  .img-wrap p::before {
    width: 20px;
    height: 20px;
    font-size: 8px;
  }
}
</style>
